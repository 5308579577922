.saleDetails {
  margin: 0 auto 110px;
  max-width: calc(100% - 30px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus-visible {
  outline: none;
}

.saleDetailsList {
  padding: 30px;
  border-radius: 5px;
  position: relative;
}
.saleDetailsList .table-responsive {
  overflow: auto;
  max-height: 335px;
}
.saleDetailsList::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -4px;
  transform: translateY(-50%) rotate(180deg);
  width: 35px;
  height: 80%;
  background-image: url("../../../public/images/side header-frame.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  filter: drop-shadow(0px 1px 3px #50dbff);
}
.saleDetailsList::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%);
  width: 35px;
  height: 80%;
  background-image: url("../../../public/images/side header-frame.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  filter: drop-shadow(0px 1px 3px #50dbff);
}
.saleDetailsList table {
  min-width: 700px;
}
.saleDetailsList table th {
  background-color: #153953;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
}
.saleDetailsList table td {
  font-size: 16px;
  padding: 10px 15px;
  border-bottom: 1px solid #153048;
}
@media (max-width: 767px) {
  .saleDetails {
    margin: 0 auto 50px;
  }
}
@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
}/*# sourceMappingURL=SaleDetailList.css.map */