/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.projects {
    margin: 0 0 60px;

    .card-glow {
        -webkit-filter: drop-shadow(0px 1px 3px #50dbff);
        filter: drop-shadow(0px 1px 3px #50dbff);
    }

    @-webkit-keyframes blinkers {

        0%,
        100% {
            -webkit-filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
            filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
        }

        50% {
            -webkit-filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
            filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
        }
    }

    @keyframes blinkers {

        0%,
        100% {
            -webkit-filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
            filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
        }

        50% {
            -webkit-filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
            filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
        }
    }

    .blinkers {
        -webkit-filter: drop-shadow(0px 0px 2px #50dbff) hue-rotate(40deg);
        filter: drop-shadow(0px 0px 2px #50dbff) hue-rotate(40deg);
        -webkit-animation: blinkers 1.5s linear infinite;
        animation: blinkers 1.5s linear infinite;
        -webkit-transition: 0.7s;
        -o-transition: 0.7s;
        transition: 0.7s;
    }

    .sale-card.active_Class {
        svg {
            -webkit-filter: drop-shadow(0px 1px 3px #50dbff);
            filter: drop-shadow(0px 1px 3px #50dbff);
        }

    }
}

.countBox {
    .countinbox {

        height: 100%;
        position: relative;


    }


    svg {
        display: block;


        path {}
    }

    h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px;
        color: #fff;
        text-shadow: 2px 2px 5px #50dbff;
    }

    p {

        font-weight: 500;

    }
}

.col-sm-6 {
    padding: 15px;
}


.listBoxInner {

    padding: 0 10px;
    position: relative;


    .test{
        &:after {
            background-image: none !important;
            animation: none !important;

        }
    }

    .meter {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        height: 25px;
        position: relative;
        background: #2222;
        padding: 6px;
        border-radius: 6px;
        -webkit-box-shadow: inset 0px 0px 4px 3px rgb(1 161 254);
        box-shadow: inset 0px 0px 4px 3px rgb(1 161 254);

        >span {
            display: block;
            height: 100%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            position: relative;
            overflow: hidden;
        }

        .animateline {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            height: 100%;
            background: #38bff87a;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            -webkit-transition: 0.7s;
            -o-transition: 0.7s;
            transition: 0.7s;
            -webkit-animation: line_Animation 5s linear infinite;
            animation: line_Animation 5s linear infinite;

            @-webkit-keyframes progress-anime {

                0% {
                    -webkit-transform: translate(0px, 0px) scale(1);
                    transform: translate(0px, 0px) scale(1);
                    opacity: 1;
                    -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                }

                100% {
                    -webkit-transform: translate(12px, 0px) scale(1);
                    transform: translate(12px, 0px) scale(1);
                    opacity: 0;
                    -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                }
            }

            @keyframes progress-anime {

                0% {
                    -webkit-transform: translate(0px, 0px) scale(1);
                    transform: translate(0px, 0px) scale(1);
                    opacity: 1;
                    -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                }

                100% {
                    -webkit-transform: translate(12px, 0px) scale(1);
                    transform: translate(12px, 0px) scale(1);
                    opacity: 0;
                    -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                    filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                }
            }


            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: url('../../../public/images/arrow.svg');
                z-index: 1;
                background-size: 17px 27px;
                -webkit-animation: move 2s linear infinite;
                animation: move 2s linear infinite;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                overflow: hidden;
                -webkit-filter: drop-shadow(0 0 1px #3af8ff) brightness(2);
                filter: drop-shadow(0 0 1px #3af8ff) brightness(2);
                -webkit-animation: progress-anime 1s linear infinite;
                animation: progress-anime 1s linear infinite;
                -webkit-transition: 0.5s;
                -o-transition: 0.5s;
                transition: 0.5s;
            }
        }
    }

    .animate {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: -o-linear-gradient(135deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
        background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
        z-index: 1;
        background-size: 50px 50px;
        -webkit-animation: move 2s linear infinite;
        animation: move 2s linear infinite;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        overflow: hidden;
    }


    .profileInfo {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .profilePic {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40px;
        flex: 0 0 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0px;
        z-index: 10;
    }



    .profileInfo {
        ul {


            li {
                display: none;
                text-align: center;
                font-weight: 800;


                span {
                    width: 8px;
                    height: 8px;
                    border-radius: 50px;
                    display: inline-block;
                    margin-right: 4px;
                }

                &.upcoming {
                    color: #d29813;
                }

                &.saleLive {
                    color: #10b981;
                }

                &.saleEnded {
                    color: red;
                }

                &.active {
                    display: inline-block;
                }
            }
        }
    }

    h2 {
        font-size: 22px;
        font-weight: 600;
        margin: 0;
        display: block;
    }

    .subtitle {
        font-size: 15px;
        font-weight: 600;
        color: #888;
        // margin: 0px;
        display: block;
    }

    h3 {
        font-size: 15px;
        font-weight: 600;
        margin: 0;
        display: block;
    }

    strong {
        font-size: 20px;
        font-weight: 600;
        color: #50dbff;
        margin: 5px 0px 10px;
        display: block;
    }

    .progressBar {
        margin: 3px 0 0px;
        font-size: 15px;
    }

    .progresLine {
        display: block;
        position: relative;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        background-color: rgba(72, 199, 116, 0.15);
        border-radius: 100px;
        height: 8px;

        span {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: rgb(72, 199, 116);
            border-radius: 100px;
        }
    }

    .tableBox {
        p {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 400;
            // margin: 0 0 5px;

            span {
                font-size: 15px;
                font-weight: 600;
            }

            &.ttl {
                color: #888;
                font-size: 13px;
                margin: 0 0 15px;
                font-weight: 600;

                span {
                    font-size: 13px;
                }
            }
        }
    }

    .dividerLine {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #50dbff;
        margin-top: 5px;
    }

    .saleTineView {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0px;
        z-index: 2;
        position: relative;

        .timesSale {
            margin: 0px;
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            -ms-flex-preferred-size: calc(100% - 105px);
            flex-basis: calc(100% - 105px);
            max-width: calc(100% - 105px);
            margin-right: 15px;
            padding: 0px;
            font-size: 14px;

            span {
                color: #4ccff1;
                display: block;
                font-size: 14px;
                margin-top: 4px;
                letter-spacing: 1.2px;
                font-weight: 600;
            }
        }

        .btnView {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 90px;
            flex: 0 0 90px;
            max-width: 90px;
            text-align: center;
            background-color: #133750;
            color: #ffffff;
            text-decoration: none;
            padding: 7px 5px;
            border-radius: 5px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }

        .react-countdown {
            padding: 0px;
            margin: 0px;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 4px;

                &::after {
                    top: 5px;
                    font-size: 14px;
                    right: -7px;
                }
            }

            .digit {
                color: #01FFFF;
                margin: 5px 0px 10px;
                display: block;
                font-size: 14px;
                font-weight: bold;
            }

            .text {
                display: none;
            }
        }
    }



    &:hover {
        border-color: #01FFFF;

        .saleTineView .btnView {
            background-color: #50dbff;
            color: #ffff;
            font-weight: 600;
        }

        &::before {
            opacity: 1;
            mix-blend-mode: unset;
        }

        &::after {
            opacity: 1;
            mix-blend-mode: unset;

        }
    }

    &.active {
        // -webkit-animation: blinkOcPublicBrn 1.5s infinite;
        // animation: blinkOcPublicBrn 1.5s infinite;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-shadow: none;

        @-webkit-keyframes blinkOcPublicBrn {
            0% {
                border-color: #125d60;
                opacity: 1;
                -webkit-box-shadow: none;
                box-shadow: none;
                text-shadow: none;
            }

            49% {
                border-color: #02ffff;
                color: #02ffff;
                text-shadow:
                    -0.2rem -0.2rem 1rem #ffffff,
                    0.2rem 0.2rem 1rem #ffffff,
                    0 0 2rem #02ffff,
                    0 0 4rem #02ffff,
                    0 0 6rem #02ffff,
                    0 0 8rem #02ffff,
                    0 0 10rem #02ffff;
                -webkit-box-shadow:
                    0 0 .1rem #125d60,
                    inset 0 0 .1rem #125d60,
                    0 0 2rem #125d60,
                    inset 0 0 0rem #125d60,
                    0 0 1rem #125d60,
                    inset 0 0 1rem #02ffff;
                box-shadow:
                    0 0 .1rem #125d60,
                    inset 0 0 .1rem #125d60,
                    0 0 2rem #125d60,
                    inset 0 0 0rem #125d60,
                    0 0 1rem #125d60,
                    inset 0 0 1rem #02ffff;

            }

            50% {
                border-color: #125d60;
                opacity: 1;
                -webkit-box-shadow: none;
                box-shadow: none;
                text-shadow: none;

            }
        }

        @keyframes blinkOcPublicBrn {
            0% {
                border-color: #125d60;
                opacity: 1;
                -webkit-box-shadow: none;
                box-shadow: none;
                text-shadow: none;
            }

            49% {
                border-color: #02ffff;
                color: #02ffff;
                text-shadow:
                    -0.2rem -0.2rem 1rem #ffffff,
                    0.2rem 0.2rem 1rem #ffffff,
                    0 0 2rem #02ffff,
                    0 0 4rem #02ffff,
                    0 0 6rem #02ffff,
                    0 0 8rem #02ffff,
                    0 0 10rem #02ffff;
                -webkit-box-shadow:
                    0 0 .1rem #125d60,
                    inset 0 0 .1rem #125d60,
                    0 0 2rem #125d60,
                    inset 0 0 0rem #125d60,
                    0 0 1rem #125d60,
                    inset 0 0 1rem #02ffff;
                box-shadow:
                    0 0 .1rem #125d60,
                    inset 0 0 .1rem #125d60,
                    0 0 2rem #125d60,
                    inset 0 0 0rem #125d60,
                    0 0 1rem #125d60,
                    inset 0 0 1rem #02ffff;

            }

            50% {
                border-color: #125d60;
                opacity: 1;
                -webkit-box-shadow: none;
                box-shadow: none;
                text-shadow: none;

            }
        }

        .infoBox {
            text-shadow: none;
        }
    }
}



// @media only screen and (max-width: 640px) {
//     .sale-card {
//         width: 440px;
//         height: 460px;
//     }
// }

@media only screen and (max-width: 575px) {
    .projects .countBox .countinbox {
        height: auto;
    }
}

@media only screen and (max-width: 440px) {
    .projects {
        .sale-card {
            height: 410px;
            width: 355px;
        }
    }
}

@media only screen and (max-width: 360px) {
    .projects {
        .sale-card {
            height: 406px;
            width: 350px
        }
    }
}