@tailwind base;
@tailwind components;
@tailwind utilities;
*,
*::before,
*::after {
  box-sizing: border-box;
}

.bg-themedark {
  background-color: #125D60;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
dl,
ol,
ul {
  margin-bottom: 0;
}

.ml-auto {
  margin-left: auto;
}

@font-face {
  font-family: "defiworld-Regular";
  src: url("../fonts/defiworld-Regular.eot");
  src: url("../fonts/defiworld-Regular.eot");
  src: url("../fonts/defiworld-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/defiworld-Regular.woff2") format("woff2"), url("../fonts/defiworld-Regular.woff") format("woff"), url("../fonts/defiworld-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Bold";
  src: url("../fonts/defiworld-Bold.eot");
  src: url("../fonts/defiworld-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/defiworld-Bold.woff2") format("woff2"), url("../fonts/defiworld-Bold.woff") format("woff"), url("../fonts/defiworld-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "defiworld-Bold1";
  src: url("../fonts/defiworld-Bold1.eot");
  src: url("../fonts/defiworld-Bold1.eot?#iefix") format("embedded-opentype"), url("../fonts/defiworld-Bold1.woff2") format("woff2"), url("../fonts/defiworld-Bold1.woff") format("woff"), url("../fonts/defiworld-Bold1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.btn-main .btnsvgbox {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 11rem;
  height: 6rem;
}

.center-position {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header_component_center {
  position: absolute;
  height: 105%;
  background: linear-gradient(102deg, #163954, #062a3b);
  transform: scale(1.05) perspective(300px) rotateX(318deg);
  border: 2.5px solid #50dbff;
  border-top: none;
  z-index: -1;
}
.header_component_center::after {
  content: "";
  position: absolute;
  top: 0%;
  left: -1%;
  display: block;
  width: 1%;
  height: 44.6%;
  background: linear-gradient(2deg, #053454, #123650);
}
.header_component_center::before {
  content: "";
  position: absolute;
  top: 0%;
  right: -1%;
  display: block;
  width: 1%;
  height: 44.6%;
  background: linear-gradient(90deg, #022c40, #0a2e41);
}

.help-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.help-button {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}
.help-button:hover {
  background-color: rgba(80, 220, 255, 0.1725490196);
}

.help-icon {
  width: 40px;
  height: 40px;
}

.tooltip {
  position: absolute;
  background: #ffffff;
  color: #000;
  padding: 5px 8px;
  font-size: 12px;
  width: 70px;
  border-radius: 4px;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  font-weight: 400;
  visibility: visible;
  pointer-events: auto;
  font-family: "defiworld-bold1";
  animation: tooltipAnimation 4s infinite;
}
.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes tooltipAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -20px);
  }
  50% {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -20px);
  }
}
.help-button span,
.help-button .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.header_component_uper {
  position: absolute;
  height: 53%;
  background: linear-gradient(102deg, #163954, #062a3b);
  transform: scale(1.05) perspective(300px) rotateX(328deg);
  border: 2.5px solid #50dbff;
  z-index: -2;
}
.header_component_uper::before {
  content: "";
  position: absolute;
  top: 88%;
  left: -4.3%;
  display: block;
  width: 4%;
  height: 15%;
  background: #000;
}
.header_component_uper::after {
  content: "";
  position: absolute;
  top: 88%;
  right: -4.3%;
  display: block;
  width: 4%;
  height: 15%;
  background: #000;
}

.header_component_border_outer {
  position: absolute;
  height: 25%;
  transform: scale(1.05) perspective(300px) rotateX(328deg);
  border: 2.5px solid #50dbff;
  border-top: 0;
  z-index: -3;
}
.header_component_border_outer::before {
  content: "";
  position: absolute;
  width: 12%;
  height: 75%;
  top: 12%;
  left: 16%;
  border: 2px solid #01a1fe;
  transform: skewX(200deg);
}
.header_component_border_outer::after {
  content: "";
  position: absolute;
  width: 12%;
  height: 75%;
  top: 12%;
  right: 16%;
  border: 2px solid #01a1fe;
  transform: skewX(155deg);
}

.header_component_border_inner {
  position: absolute;
  height: 50%;
  transform: scale(1.05) perspective(300px) rotateX(340deg);
  border: 2.5px solid #50dbff;
  z-index: -3;
}
.header_component_border_inner::after {
  content: "";
  position: absolute;
  top: 90%;
  left: 0;
  display: block;
  width: 6.3%;
  height: 10%;
  background: #01a1fe;
}
.header_component_border_inner::before {
  content: "";
  position: absolute;
  top: 90%;
  right: 0;
  display: block;
  width: 6.3%;
  height: 10%;
  background: #01a1fe;
}
@keyframes balls-anime {
  0% {
    filter: drop-shadow(0px 0px 8px #50dbff) drop-shadow(0px 0px 5px #50dbff);
    opacity: 1;
  }
  100% {
    filter: drop-shadow(0px 0px 8px rgba(80, 220, 255, 0.4509803922)) drop-shadow(0px 0px 5px rgba(80, 220, 255, 0.4117647059));
    opacity: 0.2;
  }
}
@keyframes balls-anime-alter {
  0% {
    filter: drop-shadow(0px 0px 8px #1f7cf1) drop-shadow(0px 0px 5px #50dbff);
    opacity: 1;
  }
  100% {
    filter: drop-shadow(0px 0px 8px rgba(31, 126, 241, 0.4117647059)) drop-shadow(0px 0px 5px rgba(31, 126, 241, 0.4117647059));
    opacity: 0.2;
  }
}
.balls {
  animation: balls-anime 1.5s ease-in-out 0.8s infinite normal forwards;
  background: radial-gradient(#50dbff, #000);
  filter: drop-shadow(0 0 8px #50dbff) drop-shadow(0 0 5px #50dbff);
  transition: all ea 0.7s;
}

.balls:nth-child(2) {
  animation: balls-anime-alter 1.5s ease-in-out 0.9s infinite normal forwards;
  background: radial-gradient(#1f7cf1, #000);
  filter: drop-shadow(0 0 8px #1f7cf1) drop-shadow(0 0 5px #1f7cf1);
}

.balls:nth-child(3) {
  animation: balls-anime 1.5s ease-in-out 1s infinite normal forwards;
}

.logo-glow img {
  animation: logo_anime 2.5s linear infinite normal forwards;
}
@keyframes arrow-anime-alter {
  0% {
    transform: translate(0px, 0px) scale(1);
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
  }
  100% {
    transform: translate(-8px, 0px) scale(1);
    opacity: 0;
    filter: drop-shadow(0px 0px 2px rgba(54, 193, 231, 0.2549019608));
  }
}
.active_Class {
  opacity: 1;
}
.active_Class .logo-brand img {
  animation: logo_anime 2.5s linear infinite normal forwards;
}
.active_Class .arrow-aniamtion-svg {
  animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.active_Class .arrow-aniamtion-svg:nth-child(2) {
  animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
}
.active_Class .arrow-aniamtion-svg:nth-child(3) {
  animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
}
.active_Class .light-animation {
  animation: light-anime 1.5s ease-in-out 0.8s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.active_Class:nth-child(2) {
  animation: light-anime 1.5s ease-in-out 0.6s infinite normal forwards;
}
.active_Class:nth-child(3) {
  animation: light-anime 1.5s ease-in-out 0.4s infinite normal forwards;
}
.active_Class .arrow-animation-alter {
  animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.active_Class .arrow-animation-alter:nth-child(2) {
  animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
}
.active_Class .arrow-animation-alter:nth-child(3) {
  animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
}
@keyframes arrow-anime-alter-head {
  0% {
    transform: translate(0px, 0px) scale(1) rotate(180deg);
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
  }
  100% {
    transform: translate(-8px, 0px) scale(1) rotate(180deg);
    opacity: 0;
    filter: drop-shadow(0px 0px 2px rgba(54, 193, 231, 0.2549019608));
  }
}
.arrow-animation-alter.head {
  animation: arrow-anime-alter-head 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.arrow-animation-alter.head:nth-child(2) {
  animation: arrow-anime-alter-head 1.5s ease-in-out 0.6s infinite normal forwards;
}
.arrow-animation-alter.head:nth-child(3) {
  animation: arrow-anime-alter-head 1.5s ease-in-out 0.8s infinite normal forwards;
}

.boxinner .arrow-animation-alter,
.countBox .arrow-animation-alter,
.refDetails .arrow-animation-alter {
  animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.boxinner .arrow-animation-alter:nth-child(2),
.countBox .arrow-animation-alter:nth-child(2),
.refDetails .arrow-animation-alter:nth-child(2) {
  animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
}
.boxinner .arrow-animation-alter:nth-child(3),
.countBox .arrow-animation-alter:nth-child(3),
.refDetails .arrow-animation-alter:nth-child(3) {
  animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
}
@keyframes arrow-anime {
  0% {
    transform: translate(0px, 0px) scale(1);
    opacity: 1;
    filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
  }
  100% {
    transform: translate(8px, 0px) scale(1);
    opacity: 0;
    filter: drop-shadow(0px 0px 2px rgba(54, 193, 231, 0.2549019608));
  }
}
.arrow-aniamtion {
  animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.arrow-aniamtion:nth-child(2) {
  animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
}
.arrow-aniamtion:nth-child(3) {
  animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
}
@keyframes light-anime {
  0%, 100% {
    opacity: 1;
    filter: brightness(1.8);
  }
  50% {
    opacity: 0.3;
    filter: brightness(0.2);
  }
}
@keyframes logo_anime {
  0%, 100% {
    filter: drop-shadow(0px 0px 6px #3af8ff);
  }
  50% {
    filter: drop-shadow(0px 0px 0px rgba(58, 248, 255, 0.3882352941));
  }
}
.timmer {
  height: 220px;
}
.timmer .arrow-animation-alter {
  animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.timmer .arrow-animation-alter:nth-child(2) {
  animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
}
.timmer .arrow-animation-alter:nth-child(3) {
  animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
}
.timmer .arrow-aniamtion-svg {
  animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
  transition: all 0.7s ease-in-out;
}
.timmer .arrow-aniamtion-svg:nth-child(2) {
  animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
}
.timmer .arrow-aniamtion-svg:nth-child(3) {
  animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
}

.listBoxInner .logo-brand img {
  box-shadow: 0px 0px 10px -2px #2c2c2c;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "defiworld-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  text-align: left;
  background-color: #000000;
  overflow-x: hidden !important;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/dwc-coin.gif);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -2;
  filter: blur(0px);
  opacity: 0.06;
  mix-blend-mode: difference;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #214e7a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #50dbff;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "defiworld-Bold";
  line-height: 1 !important;
}

.title-defi-font {
  font-family: "defiworld-Bold1";
}

img {
  max-width: 100%;
  height: auto;
}

.bg-themedark {
  background-color: #125D60;
}

.bg-themelight {
  background-color: #01FFFF;
}

.oc_row {
  display: flex;
  flex-wrap: wrap;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.oc_col6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
}

section {
  overflow: hidden;
}

.oc_container {
  position: relative;
  width: 1320px;
  max-width: calc(100% - 15px);
  margin-left: auto;
  margin-right: auto;
}
@keyframes blinkers {
  0%, 100% {
    filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
  }
  50% {
    filter: drop-shadow(0px 0px 2px rgba(80, 220, 255, 0.3490196078)) hue-rotate(40deg) brightness(0.6);
  }
}
.oc_container .blinkers {
  filter: drop-shadow(0px 0px 2px #50dbff) hue-rotate(40deg);
  animation: blinkers 1.5s linear infinite;
  transition: 0.7s;
}

.blacklineone {
  position: absolute;
  top: 47%;
  left: 8.9%;
  display: block;
  width: 2.8%;
  height: 1.5%;
  background: black;
  z-index: 2;
  transform: skewX(70deg);
}

.blacklinetwo {
  position: absolute;
  top: 47%;
  right: 8.9%;
  display: block;
  width: 2.8%;
  height: 1.5%;
  background: black;
  z-index: 2;
  transform: skewX(300deg);
}

.arrow-frameone {
  content: "";
  position: absolute;
  width: 7%;
  height: 19%;
  bottom: 31%;
  left: 25%;
  border: 2px solid #01a1fe;
  transform: skewX(232deg);
}

.arrow-frametwo {
  content: "";
  position: absolute;
  width: 7%;
  height: 19%;
  bottom: 31%;
  right: 25%;
  border: 2px solid #01a1fe;
  transform: skewX(-232deg);
}

.header-frame-uper::before {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 53%;
  background: linear-gradient(102deg, #163954, #062a3b);
  transform: scale(1.05) perspective(300px) rotateX(328deg);
  border: 2.5px solid #50dbff;
  z-index: 1;
}

.header-frame-center::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(102deg, #163954, #062a3b);
  transform: scale(1.05) perspective(300px) rotateX(315deg);
  border: 2.5px solid #50dbff;
  border-top: none;
  z-index: -1;
}

.header-frame-outer-line::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2.5px solid #50dbff;
  height: 50%;
  position: absolute;
  transform: scale(1.05) perspective(300px) rotateX(340deg);
  z-index: -4;
}

.header-frame-inner-line::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 20px;
  width: 100%;
  height: 25%;
  border: 2.5px solid #50dbff;
  border-top: none;
  transform: scale(1.05) perspective(300px) rotateX(328deg);
  z-index: -4;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

h2 {
  text-shadow: 2px 2px 5px #50dbff;
}

.tittle-shadow {
  text-shadow: 0px 5px 0px #000506, 2px 5px 0px #000506;
}

.title {
  text-align: center;
  padding: 0;
  border: none;
}
.title h2 {
  text-align: center;
  display: block;
  color: white;
  text-transform: capitalize;
  text-shadow: 2px 2px 50px #50dbff;
}

.oc_btn {
  background-color: #153852;
  border-radius: 5px;
  min-width: 180px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  box-shadow: 0 3px 13px -4px #040404;
}
.oc_btn:hover {
  background-color: #50dbff;
  color: #000 !important;
}
.oc_btn:nth-last-child(1) {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #1e679d;
  font-weight: 400;
}
.oc_btn:nth-last-child(1):hover {
  background-color: #50dbff;
  color: #000 !important;
}
.oc_btn.copyRefferalCode {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.oc_btn.copyRefferalCode img {
  height: auto;
  width: auto;
  margin-left: 10px;
  max-height: 19px;
  max-width: 20px;
}

.borderboxmain {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1;
}
.borderboxmain img {
  width: 97vw;
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
  object-fit: contain;
}
.borderboxmain img.linetopleft {
  top: 1vw;
  left: 1vw;
  -o-object-position: left;
  object-position: left;
}
.borderboxmain img.linebottomright {
  bottom: 1vw;
  right: 1vw;
  -o-object-position: right;
  object-position: right;
}

.main {
  padding: 0px 5vw 0vw;
  position: relative;
}

.tooltipIcon {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: bold;
  border: none;
  margin-left: 6px;
  font-size: 16px;
  line-height: 1;
}

@media (min-width: 1700px) {
  header {
    height: 210px !important;
  }
}
@media (min-width: 1400px) {
  .header_component_uper {
    height: 53%;
  }
  .header_component_center {
    top: -1px;
    left: 35%;
    width: 30%;
    transform: scale(1.05) perspective(300px) rotateX(315deg);
    z-index: -2;
  }
  .header_component_border_outer {
    position: absolute;
    left: 13%;
    height: 25%;
  }
  .header_component_border_inner {
    position: absolute;
    height: 50%;
  }
}
@media (max-width: 1024px) {
  .header_component_uper {
    transform: scale(1.05) perspective(300px) rotateX(343deg);
  }
  .header_component_center::before {
    height: 48.6%;
  }
  .header_component_center::after {
    height: 48.6%;
  }
  .header_component_border_outer::before {
    transform: skewX(200deg);
    left: 12%;
  }
  .header_component_border_outer::after {
    right: 12%;
  }
}
@media only screen and (max-width: 992px) {
  .main {
    padding: 50px 2vw 5vw;
  }
  .oc_btn {
    padding: 10px 10px;
    min-width: auto;
    font-size: 14px;
  }
}
@media (max-width: 786px) {
  .header_component_border_outer::before {
    display: none;
  }
  .header_component_border_outer::after {
    display: none;
  }
}
@media only screen and (min-width: 767px) {
  .md\:justify-start {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 767px) {
  .timmer {
    height: 185px;
  }
  .buySell {
    margin: 0 0;
  }
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }
  .oc_container {
    max-width: calc(100% - 15px);
  }
  .oc_btn.copyRefferalCode {
    margin-left: auto;
    margin-right: auto;
  }
  .logoDesktop {
    top: 80px;
  }
  .logoDesktop ul li {
    font-size: 22px;
  }
}
@media only screen and (max-width: 640px) {
  .timmer {
    height: 140px;
  }
}
@media (max-width: 520px) {
  .tooltip {
    font-size: 10px;
    width: 60px;
  }
  .header_component_center {
    height: 100%;
  }
  .header_component_center::after {
    height: 51.6%;
  }
  .header_component_center::before {
    height: 51.6%;
  }
}
@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }
  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: 100%;
  }
}
@media (max-width: 396px) {
  .timmer {
    width: 375px;
  }
  .timmer .timmerBox {
    width: 375px;
  }
}
@media (max-width: 390px) {
  .main {
    padding: 0px 2vw 0vw;
  }
}
@media (max-width: 376px) {
  .timmer {
    width: 320px;
  }
  .timmer .timmerBox {
    width: 320px;
    padding: 10px 0px;
  }
  .timmer .timmerBox .title-defi-font {
    font-size: 16px;
  }
  .timmer .timmerBox .timenum {
    margin-top: 35px;
    font-size: 16px;
  }
}/*# sourceMappingURL=style.css.map */