/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.balanceOverview {
    margin: 0 auto 60px;
    max-width: 1100px;
}

.balanceOverviewBox {

    padding: 50px;
    border-radius: 5px;
    position: relative;


    &::before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        background: rgba(33, 78, 122, 0.31);
        border: 1px solid #50dbff;
        border-radius: 14px;
        -webkit-transform: scale(1) perspective(300px) rotateX(359deg);
        transform: scale(1) perspective(300px) rotateX(359deg);
        width: 100%;
        z-index: -1;
    }


    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-bottom: 30px;

            p {
                margin: 0px;

                span {
                    font-size: 12px;
                }
            }

            strong {
                margin-left: 15px;
                color: #50dbff;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;

                &.countDown {
                    letter-spacing: 2px;
                }
            }
        }
    }

    .buttonGrp {}
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;

        &::before {
            top: 0px;
        }
    }

}