/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
.balanceOverview {
  margin: 0 auto 60px;
  max-width: 1100px;
}

.balanceOverviewBox {
  padding: 50px;
  border-radius: 5px;
  position: relative;
}
.balanceOverviewBox::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  background: rgba(33, 78, 122, 0.31);
  border: 1px solid #50dbff;
  border-radius: 14px;
  transform: scale(1) perspective(300px) rotateX(359deg);
  width: 100%;
  z-index: -1;
}
.balanceOverviewBox ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.balanceOverviewBox ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.balanceOverviewBox ul li p {
  margin: 0px;
}
.balanceOverviewBox ul li p span {
  font-size: 12px;
}
.balanceOverviewBox ul li strong {
  margin-left: 15px;
  color: #50dbff;
  flex: 0 0 auto;
}
.balanceOverviewBox ul li strong.countDown {
  letter-spacing: 2px;
}
@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
  .balanceOverviewBox::before {
    top: 0px;
  }
}/*# sourceMappingURL=BalanceOverview.css.map */