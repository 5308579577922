.publicSale {
    margin: 0px 0px 100px;

    .infobox {
        margin-bottom: 15px;

        h1 {
            margin: 0 0 30px;
            font-size: 48px;
            font-weight: bold;
            text-shadow: 1px 4px 0px #50dbff, -1px 3px 0px #50dbff;
        }

        ul {
            padding: 0px;
            margin: 0px 0px 30px;
            list-style: none;

            li {
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 20px;
            margin: 0 0 10px;
        }

        .copybox {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            background-color: #125d60;
            max-width: -webkit-max-content;
            max-width: -moz-max-content;
            max-width: max-content;
            padding: 12px 15px;
            border-radius: 5px;
            color: #02ffff;

            p {
                margin: 0px;
            }

            svg {
                font-size: 24px;
                margin-left: 20px;
                cursor: pointer;
                fill: #fff43c;
            }
        }
    }

    .imageBox {
        text-align: center;

        img {
            max-width: 500px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .publicSale {
        text-align: center;
        margin: 0px 0px 50px;
    }

    .publicSale .infobox .copybox {
        margin: 20px auto 0px;
    }
}


@media only screen and (min-width: 768px) {
    .imageBox {
        img {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }
}