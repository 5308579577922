/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

// Footer Css Start
footer {
    text-align: center;
    padding: 30px 15px;

    .socialIcons {
        margin-top: 70px
    }

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        position: relative;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;

        li {
            margin: 0px 5px;

            a {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                border: 1px solid rgba(1, 255, 255, 0.322);
            }

            svg {
                fill: #01ffff;
                width: 100%;
                height: 100%;
                -o-object-fit: contain;
                   object-fit: contain;
                padding: 12px;
            }
        }
    }

    .copyright {
        text-align: center;

        p {
            margin: 0px;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 575px) {
    footer {
        padding: 43px 10px;
    }

    footer ul li {
        margin: 0px 3px;
    }

    footer ul li a {
        width: 35px;
        height: 35px;
    }

    footer ul li svg {
        padding: 7px;
    }
}

// Footer Css End