@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.bg-themedark {
    background-color: #125D60;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

dl,
ol,
ul {
    margin-bottom: 0;
}

.ml-auto {
    margin-left: auto;
}

@font-face {
    font-family: 'defiworld-Regular';
    src: url('../fonts/defiworld-Regular.eot');
    src: url('../fonts/defiworld-Regular.eot');
    src: url('../fonts/defiworld-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/defiworld-Regular.woff2') format('woff2'),
        url('../fonts/defiworld-Regular.woff') format('woff'),
        url('../fonts/defiworld-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "defiworld-Bold";
    src: url('../fonts/defiworld-Bold.eot');
    src: url('../fonts/defiworld-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/defiworld-Bold.woff2') format('woff2'),
        url('../fonts/defiworld-Bold.woff') format('woff'),
        url('../fonts/defiworld-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "defiworld-Bold1";
    src: url('../fonts/defiworld-Bold1.eot');
    src: url('../fonts/defiworld-Bold1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/defiworld-Bold1.woff2') format('woff2'),
        url('../fonts/defiworld-Bold1.woff') format('woff'),
        url('../fonts/defiworld-Bold1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.btn-main {
    .btnsvgbox {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 11rem;
        height: 6rem;
    }
}

.center-position {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.header_component_center {
    position: absolute;
    height: 105%;
    background: -o-linear-gradient(348deg, #163954, #062a3b);
    background: linear-gradient(102deg, #163954, #062a3b);
    -webkit-transform: scale(1.05) perspective(300px) rotateX(318deg);
    transform: scale(1.05) perspective(300px) rotateX(318deg);
    border: 2.5px solid #50dbff;
    border-top: none;
    z-index: -1;

    &::after {
        content: "";
        position: absolute;
        top: 0%;
        left: -1%;
        display: block;
        width: 1%;
        height: 44.6%;
        background: -o-linear-gradient(88deg, #053454, #123650);
        background: linear-gradient(2deg, #053454, #123650);
    }

    &::before {
        content: "";
        position: absolute;
        top: 0%;
        right: -1%;
        display: block;
        width: 1%;
        height: 44.6%;
        background: -webkit-gradient(linear, left top, right top, from(#022c40), to(#0a2e41));
        background: -o-linear-gradient(left, #022c40, #0a2e41);
        background: linear-gradient(90deg, #022c40, #0a2e41);
    }

}

.help-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
}

.help-button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;

    &:hover {
        background-color: rgba(80, 220, 255, 0.1725490196);
    }
}

.help-icon {
    width: 40px;
    height: 40px;
}

.tooltip {
    position: absolute;
    background: #ffffff;
    color: #000;
    padding: 5px 8px;
    font-size: 12px;
    width: 70px;
    border-radius: 4px;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    font-weight: 400;
    visibility: visible;
    pointer-events: auto;
    font-family: 'defiworld-bold1';
    animation: tooltipAnimation 4s infinite;

    &::before {
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        background: #ffffff;
        bottom: -3px;
        left: 50%;
        transform: translate(-50%) rotate(45deg);
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
}

@keyframes tooltipAnimation {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -20px);

    }

    50% {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, 0);
    }

    100% {
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -20px);
    }
}

.help-button span,
.help-button .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}


.header_component_uper {
    position: absolute;
    height: 53%;
    background: -o-linear-gradient(348deg, #163954, #062a3b);
    background: linear-gradient(102deg, #163954, #062a3b);
    -webkit-transform: scale(1.05) perspective(300px) rotateX(328deg);
    transform: scale(1.05) perspective(300px) rotateX(328deg);
    border: 2.5px solid #50dbff;
    z-index: -2;

    &::before {
        content: "";
        position: absolute;
        top: 88%;
        left: -4.3%;
        display: block;
        width: 4%;
        height: 15%;
        background: #000;
    }

    &::after {
        content: "";
        position: absolute;
        top: 88%;
        right: -4.3%;
        display: block;
        width: 4%;
        height: 15%;
        background: #000;
    }
}

.header_component_border_outer {
    position: absolute;
    height: 25%;
    -webkit-transform: scale(1.05) perspective(300px) rotateX(328deg);
    transform: scale(1.05) perspective(300px) rotateX(328deg);
    border: 2.5px solid #50dbff;
    border-top: 0;
    z-index: -3;

    &::before {
        content: '';
        position: absolute;
        width: 12%;
        height: 75%;
        top: 12%;
        left: 16%;
        border: 2px solid #01a1fe;
        -webkit-transform: skewX(200deg);
        -ms-transform: skewX(200deg);
        transform: skewX(200deg);
    }

    &::after {
        content: '';
        position: absolute;
        width: 12%;
        height: 75%;
        top: 12%;
        right: 16%;
        border: 2px solid #01a1fe;
        -webkit-transform: skewX(155deg);
        -ms-transform: skewX(155deg);
        transform: skewX(155deg);
    }
}

.header_component_border_inner {
    position: absolute;
    height: 50%;
    -webkit-transform: scale(1.05) perspective(300px) rotateX(340deg);
    transform: scale(1.05) perspective(300px) rotateX(340deg);
    border: 2.5px solid #50dbff;
    z-index: -3;

    &::after {
        content: "";
        position: absolute;
        top: 90%;
        left: 0;
        display: block;
        width: 6.3%;
        height: 10%;
        background: #01a1fe;
    }

    &::before {
        content: "";
        position: absolute;
        top: 90%;
        right: 0;
        display: block;
        width: 6.3%;
        height: 10%;
        background: #01a1fe;
    }
}


@-webkit-keyframes balls-anime {
    0% {
        -webkit-filter: drop-shadow(0px 0px 8px #50dbff) drop-shadow(0px 0px 5px #50dbff);
        filter: drop-shadow(0px 0px 8px #50dbff) drop-shadow(0px 0px 5px #50dbff);
        opacity: 1;
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 8px #50dcff73) drop-shadow(0px 0px 5px #50dcff69);
        filter: drop-shadow(0px 0px 8px #50dcff73) drop-shadow(0px 0px 5px #50dcff69);
        opacity: 0.2;
    }
}


@keyframes balls-anime {
    0% {
        -webkit-filter: drop-shadow(0px 0px 8px #50dbff) drop-shadow(0px 0px 5px #50dbff);
        filter: drop-shadow(0px 0px 8px #50dbff) drop-shadow(0px 0px 5px #50dbff);
        opacity: 1;
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 8px #50dcff73) drop-shadow(0px 0px 5px #50dcff69);
        filter: drop-shadow(0px 0px 8px #50dcff73) drop-shadow(0px 0px 5px #50dcff69);
        opacity: 0.2;
    }
}

@-webkit-keyframes balls-anime-alter {
    0% {
        -webkit-filter: drop-shadow(0px 0px 8px #1f7cf1) drop-shadow(0px 0px 5px #50dbff);
        filter: drop-shadow(0px 0px 8px #1f7cf1) drop-shadow(0px 0px 5px #50dbff);
        opacity: 1;
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 8px #1f7ef169) drop-shadow(0px 0px 5px #1f7ef169);
        filter: drop-shadow(0px 0px 8px #1f7ef169) drop-shadow(0px 0px 5px #1f7ef169);
        opacity: 0.2;
    }
}

@keyframes balls-anime-alter {
    0% {
        -webkit-filter: drop-shadow(0px 0px 8px #1f7cf1) drop-shadow(0px 0px 5px #50dbff);
        filter: drop-shadow(0px 0px 8px #1f7cf1) drop-shadow(0px 0px 5px #50dbff);
        opacity: 1;
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 8px #1f7ef169) drop-shadow(0px 0px 5px #1f7ef169);
        filter: drop-shadow(0px 0px 8px #1f7ef169) drop-shadow(0px 0px 5px #1f7ef169);
        opacity: 0.2;
    }
}

.balls {
    animation: balls-anime 1.5s ease-in-out .8s infinite normal forwards;
    background: radial-gradient(#50dbff, #000);
    filter: drop-shadow(0 0 8px #50dbff) drop-shadow(0 0 5px #50dbff);
    transition: all ea .7s;
}

.balls:nth-child(2) {
    animation: balls-anime-alter 1.5s ease-in-out .9s infinite normal forwards;
    background: radial-gradient(#1f7cf1, #000);
    filter: drop-shadow(0 0 8px #1f7cf1) drop-shadow(0 0 5px #1f7cf1);
}

.balls:nth-child(3) {
    animation: balls-anime 1.5s ease-in-out 1s infinite normal forwards;
}

.logo-glow img {
    -webkit-animation: logo_anime 2.5s linear infinite normal forwards;
    animation: logo_anime 2.5s linear infinite normal forwards;
}

@-webkit-keyframes arrow-anime-alter {
    0% {
        -webkit-transform: translate(0px, 0px) scale(1);
        transform: translate(0px, 0px) scale(1);
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
        filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
    }

    100% {
        -webkit-transform: translate(-8px, 0px) scale(1);
        transform: translate(-8px, 0px) scale(1);
        opacity: 0;
        -webkit-filter: drop-shadow(0px 0px 2px #36c1e741);
        filter: drop-shadow(0px 0px 2px #36c1e741);
    }
}

@keyframes arrow-anime-alter {
    0% {
        -webkit-transform: translate(0px, 0px) scale(1);
        transform: translate(0px, 0px) scale(1);
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
        filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
    }

    100% {
        -webkit-transform: translate(-8px, 0px) scale(1);
        transform: translate(-8px, 0px) scale(1);
        opacity: 0;
        -webkit-filter: drop-shadow(0px 0px 2px #36c1e741);
        filter: drop-shadow(0px 0px 2px #36c1e741);
    }
}

.active_Class {

    opacity: 1;

    .logo-brand img {
        -webkit-animation: logo_anime 2.5s linear infinite normal forwards;
        animation: logo_anime 2.5s linear infinite normal forwards;
    }

    .arrow-aniamtion-svg {
        -webkit-animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
        animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
        -webkit-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;


        &:nth-child(2) {
            -webkit-animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
            animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
        }

        &:nth-child(3) {
            -webkit-animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
            animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
        }
    }

    .light-animation {
        -webkit-animation: light-anime 1.5s ease-in-out 0.8s infinite normal forwards;
        animation: light-anime 1.5s ease-in-out 0.8s infinite normal forwards;
        -webkit-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    &:nth-child(2) {
        -webkit-animation: light-anime 1.5s ease-in-out 0.6s infinite normal forwards;
        animation: light-anime 1.5s ease-in-out 0.6s infinite normal forwards;
    }

    &:nth-child(3) {
        -webkit-animation: light-anime 1.5s ease-in-out 0.4s infinite normal forwards;
        animation: light-anime 1.5s ease-in-out 0.4s infinite normal forwards;
    }

    .arrow-animation-alter {
        -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
        animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
        -webkit-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;

        &:nth-child(2) {
            -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
            animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
        }

        &:nth-child(3) {
            -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
            animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
        }
    }
}


@-webkit-keyframes arrow-anime-alter-head {
    0% {
        -webkit-transform: translate(0px, 0px) scale(1) rotate(180deg);
        transform: translate(0px, 0px) scale(1) rotate(180deg);
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
        filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
    }

    100% {
        -webkit-transform: translate(-8px, 0px) scale(1) rotate(180deg);
        transform: translate(-8px, 0px) scale(1) rotate(180deg);
        opacity: 0;
        -webkit-filter: drop-shadow(0px 0px 2px #36c1e741);
        filter: drop-shadow(0px 0px 2px #36c1e741);
    }
}

@keyframes arrow-anime-alter-head {
    0% {
        -webkit-transform: translate(0px, 0px) scale(1) rotate(180deg);
        transform: translate(0px, 0px) scale(1) rotate(180deg);
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
        filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
    }

    100% {
        -webkit-transform: translate(-8px, 0px) scale(1) rotate(180deg);
        transform: translate(-8px, 0px) scale(1) rotate(180deg);
        opacity: 0;
        -webkit-filter: drop-shadow(0px 0px 2px #36c1e741);
        filter: drop-shadow(0px 0px 2px #36c1e741);
    }
}


.arrow-animation-alter.head {
    -webkit-animation: arrow-anime-alter-head 1.5s ease-in-out 0.4s infinite normal forwards;
    animation: arrow-anime-alter-head 1.5s ease-in-out 0.4s infinite normal forwards;
    -webkit-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;


    &:nth-child(2) {
        -webkit-animation: arrow-anime-alter-head 1.5s ease-in-out 0.6s infinite normal forwards;
        animation: arrow-anime-alter-head 1.5s ease-in-out 0.6s infinite normal forwards;
    }

    &:nth-child(3) {
        -webkit-animation: arrow-anime-alter-head 1.5s ease-in-out 0.8s infinite normal forwards;
        animation: arrow-anime-alter-head 1.5s ease-in-out 0.8s infinite normal forwards;
    }
}


.boxinner,
.countBox,
.refDetails {
    .arrow-animation-alter {
        -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
        animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
        -webkit-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;

        &:nth-child(2) {
            -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
            animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
        }

        &:nth-child(3) {
            -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
            animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
        }
    }
}


@-webkit-keyframes arrow-anime {
    0% {
        -webkit-transform: translate(0px, 0px) scale(1);
        transform: translate(0px, 0px) scale(1);
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
        filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
    }

    100% {
        -webkit-transform: translate(8px, 0px) scale(1);
        transform: translate(8px, 0px) scale(1);
        opacity: 0;
        -webkit-filter: drop-shadow(0px 0px 2px #36c1e741);
        filter: drop-shadow(0px 0px 2px #36c1e741);
    }
}




@keyframes arrow-anime {
    0% {
        -webkit-transform: translate(0px, 0px) scale(1);
        transform: translate(0px, 0px) scale(1);
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
        filter: drop-shadow(0px 0px 2px #36c0e7) drop-shadow(0px 0px 2px #36c0e7);
    }

    100% {
        -webkit-transform: translate(8px, 0px) scale(1);
        transform: translate(8px, 0px) scale(1);
        opacity: 0;
        -webkit-filter: drop-shadow(0px 0px 2px #36c1e741);
        filter: drop-shadow(0px 0px 2px #36c1e741);
    }
}

.arrow-aniamtion {
    -webkit-animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
    animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
    -webkit-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;


    &:nth-child(2) {
        -webkit-animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
        animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
    }

    &:nth-child(3) {
        -webkit-animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
        animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
    }
}


@-webkit-keyframes light-anime {

    0%,
    100% {
        opacity: 1;
        -webkit-filter: brightness(1.8);
        filter: brightness(1.8);
    }

    50% {
        opacity: 0.3;
        -webkit-filter: brightness(0.2);
        filter: brightness(0.2);
    }
}


@keyframes light-anime {

    0%,
    100% {
        opacity: 1;
        -webkit-filter: brightness(1.8);
        filter: brightness(1.8);
    }

    50% {
        opacity: 0.3;
        -webkit-filter: brightness(0.2);
        filter: brightness(0.2);
    }
}

@-webkit-keyframes logo_anime {

    0%,
    100% {
        -webkit-filter: drop-shadow(0px 0px 6px #3af8ff);
        filter: drop-shadow(0px 0px 6px #3af8ff);
    }

    50% {
        -webkit-filter: drop-shadow(0px 0px 0px #3af8ff63);
        filter: drop-shadow(0px 0px 0px #3af8ff63);
    }
}

@keyframes logo_anime {

    0%,
    100% {
        -webkit-filter: drop-shadow(0px 0px 6px #3af8ff);
        filter: drop-shadow(0px 0px 6px #3af8ff);
    }

    50% {
        -webkit-filter: drop-shadow(0px 0px 0px #3af8ff63);
        filter: drop-shadow(0px 0px 0px #3af8ff63);
    }
}

.timmer {
    height: 220px;

    .arrow-animation-alter {
        -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite norbalanceOverviewBoxmal forwards;
        animation: arrow-anime-alter 1.5s ease-in-out 0.4s infinite normal forwards;
        -webkit-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;

        &:nth-child(2) {
            -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
            animation: arrow-anime-alter 1.5s ease-in-out 0.6s infinite normal forwards;
        }

        &:nth-child(3) {
            -webkit-animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
            animation: arrow-anime-alter 1.5s ease-in-out 0.8s infinite normal forwards;
        }
    }

    .arrow-aniamtion-svg {
        -webkit-animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
        animation: arrow-anime 1.5s ease-in-out 0.4s infinite normal forwards;
        -webkit-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;


        &:nth-child(2) {
            -webkit-animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
            animation: arrow-anime 1.5s ease-in-out 0.6s infinite normal forwards;
        }

        &:nth-child(3) {
            -webkit-animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
            animation: arrow-anime 1.5s ease-in-out 0.8s infinite normal forwards;
        }
    }
}

.listBoxInner .logo-brand img {
    box-shadow: 0px 0px 10px -2px #2c2c2c;
}

svg {}


body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'defiworld-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: white;
    text-align: left;
    background-color: #000000;
    overflow-x: hidden !important;
    word-break: break-word;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/dwc-coin.gif);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -2;
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 0.06;
        mix-blend-mode: difference;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #214e7a;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #50dbff;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'defiworld-Bold';
    line-height: 1 !important;
}

.title-defi-font {
    font-family: "defiworld-Bold1";
}

img {
    max-width: 100%;
    height: auto;
}

.bg-themedark {
    background-color: #125D60;
}

.bg-themelight {
    background-color: #01FFFF;
}

.oc_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.align-items-flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.justify-content-flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.align-items-flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.justify-content-flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.oc_col6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px;
}

section {
    overflow: hidden;
}

.oc_container {
    position: relative;
    width: 1320px;
    max-width: calc(100% - 15px);
    margin-left: auto;
    margin-right: auto;

    @-webkit-keyframes blinkers {

        0%,
        100% {
            -webkit-filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
            filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
        }

        50% {
            -webkit-filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
            filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
        }
    }

    @keyframes blinkers {

        0%,
        100% {
            -webkit-filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
            filter: drop-shadow(0px 0px 2px #5bd6f5) hue-rotate(40deg) brightness(1.5);
        }

        50% {
            -webkit-filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
            filter: drop-shadow(0px 0px 2px #50dcff59) hue-rotate(40deg) brightness(0.6);
        }
    }

    .blinkers {
        -webkit-filter: drop-shadow(0px 0px 2px #50dbff) hue-rotate(40deg);
        filter: drop-shadow(0px 0px 2px #50dbff) hue-rotate(40deg);
        -webkit-animation: blinkers 1.5s linear infinite;
        animation: blinkers 1.5s linear infinite;
        -webkit-transition: 0.7s;
        -o-transition: 0.7s;
        transition: 0.7s;
    }

}


.blacklineone {
    position: absolute;
    top: 47%;
    left: 8.9%;
    display: block;
    width: 2.8%;
    height: 1.5%;
    background: black;
    z-index: 2;
    transform: skewX(70deg);
}

.blacklinetwo {
    position: absolute;
    top: 47%;
    right: 8.9%;
    display: block;
    width: 2.8%;
    height: 1.5%;
    background: black;
    z-index: 2;
    transform: skewX(300deg);
}

.arrow-frameone {
    content: "";
    position: absolute;
    width: 7%;
    height: 19%;
    bottom: 31%;
    left: 25%;
    border: 2px solid #01a1fe;
    transform: skewX(232deg);
}

.arrow-frametwo {
    content: "";
    position: absolute;
    width: 7%;
    height: 19%;
    bottom: 31%;
    right: 25%;
    border: 2px solid #01a1fe;
    transform: skewX(-232deg);
}


.header-frame-uper {



    .blacklineone {}

    &::before {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 53%;
        background: linear-gradient(102deg, #163954, #062a3b);
        transform: scale(1.05) perspective(300px) rotateX(328deg);
        border: 2.5px solid #50dbff;
        z-index: 1;
    }
}

.header-frame-center {
    &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: -o-linear-gradient(348deg, #163954, #062a3b);
        background: linear-gradient(102deg, #163954, #062a3b);
        -webkit-transform: sscale(1.05) perspective(300px) rotateX(315deg);
        transform: scale(1.05) perspective(300px) rotateX(315deg);
        border: 2.5px solid #50dbff;
        border-top: none;
        z-index: -1;
    }
}

.header-frame-outer-line {
    &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 2.5px solid #50dbff;
        height: 50%;
        position: absolute;
        transform: scale(1.05) perspective(300px) rotateX(340deg);
        z-index: -4;
    }
}

.header-frame-inner-line {
    &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 20px;
        width: 100%;
        height: 25%;
        border: 2.5px solid #50dbff;
        border-top: none;
        transform: scale(1.05) perspective(300px) rotateX(328deg);
        z-index: -4;
    }
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

h2 {
    text-shadow: 2px 2px 5px #50dbff;
}

.tittle-shadow {
    text-shadow: 0px 5px 0px #000506, 2px 5px 0px #000506;
}


.title {
    text-align: center;
    padding: 0;
    border: none;

    h2 {
        text-align: center;
        display: block;
        // margin: 0px;
        color: #ffff;
        // font-size: 2rem;
        text-transform: capitalize;
        text-shadow: 2px 2px 50px #50dbff;
    }
}

.buttonGrp {}

.oc_btn {
    background-color: #153852;
    border-radius: 5px;

    min-width: 180px;
    font-weight: bold;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    box-shadow: 0 3px 13px -4px #040404;

    &:hover {
        background-color: #50dbff;
        color: #000 !important;
    }

    &:nth-last-child(1) {
        background-color: transparent;
        color: #ffffff;
        border: 1px solid #1e679d;
        font-weight: 400;

        &:hover {
            background-color: #50dbff;
            color: #000 !important;

        }
    }

    &.copyRefferalCode {
        text-decoration: none;
        color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-align: left;

        img {
            height: auto;
            width: auto;
            margin-left: 10px;
            max-height: 19px;
            max-width: 20px;
        }
    }
}

// Start
.borderboxmain {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100%);
    min-height: 100vh;
    z-index: -1;

    img {
        width: calc(100vw - 3vw);
        height: auto;
        display: block;
        position: absolute;
        -o-object-fit: contain;
        object-fit: contain;

        &.linetopleft {
            top: 1vw;
            left: 1vw;
            -o-object-position: left;
            object-position: left;

        }

        &.linebottomright {
            bottom: 1vw;
            right: 1vw;
            -o-object-position: right;
            object-position: right;

        }
    }
}

.main {
    padding: 0px 5vw 0vw;
    position: relative;
}

.tooltipIcon {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000000;
    font-weight: bold;
    border: none;
    margin-left: 6px;
    font-size: 16px;
    line-height: 1;

}



@import 'media';