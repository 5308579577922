.buySell {
    margin: 0 auto 60px;
    

    .buySellbox {
        height: 100%;
        position: relative;
        

        .meter {
            -webkit-box-sizing: content-box;
                    box-sizing: content-box;
            height: 25px;
            position: relative;
            background: #2222;
            padding: 6px;
            border-radius: 6px;
            -webkit-box-shadow: inset 0px 0px 4px 3px rgb(1 161 254);
                    box-shadow: inset 0px 0px 4px 3px rgb(1 161 254);

            >span {
                display: block;
                height: 100%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                position: relative;
                overflow: hidden;
            }

            .animateline {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                height: 100%;
                background: #38bff87a;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
                        box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
                -webkit-transition: 0.7s;
                -o-transition: 0.7s;
                transition: 0.7s;
                -webkit-animation: line_Animation 5s linear infinite;
                        animation: line_Animation 5s linear infinite;

                @-webkit-keyframes progress-anime {

                    0% {
                        -webkit-transform: translate(0px, 0px) scale(1);
                                transform: translate(0px, 0px) scale(1);
                        opacity: 1;
                        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                                filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                    }

                    100% {
                        -webkit-transform: translate(12px, 0px) scale(1);
                                transform: translate(12px, 0px) scale(1);
                        opacity: 0;
                        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                                filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                    }
                }

                @keyframes progress-anime {

                    0% {
                        -webkit-transform: translate(0px, 0px) scale(1);
                                transform: translate(0px, 0px) scale(1);
                        opacity: 1;
                        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                                filter: drop-shadow(0px 0px 2px #36c0e7) brightness(1.5);
                    }

                    100% {
                        -webkit-transform: translate(12px, 0px) scale(1);
                                transform: translate(12px, 0px) scale(1);
                        opacity: 0;
                        -webkit-filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                                filter: drop-shadow(0px 0px 2px #36c0e7) brightness(2);
                    }
                }


                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-image: url('../../../public/images/arrow.svg');
                    z-index: 1;
                    background-size: 17px 27px;
                    -webkit-animation: move 2s linear infinite;
                            animation: move 2s linear infinite;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    overflow: hidden;
                    -webkit-filter: drop-shadow(0 0 1px #3af8ff) brightness(2);
                            filter: drop-shadow(0 0 1px #3af8ff) brightness(2);
                    -webkit-animation: progress-anime 1s linear infinite;
                            animation: progress-anime 1s linear infinite;
                    -webkit-transition: 0.5s;
                    -o-transition: 0.5s;
                    transition: 0.5s;
                }
            }
        }

        .topttl {
            display: block;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 8px;
            text-align: right;
        }

        .fillBox {
            display: block;
            width: 100%;
            padding: 25px;
            background-color: rgba(255, 255, 255, 0.161);
            border: none;
            border-radius: 5px;
            margin-bottom: 8px;
            font-size: 20px;
            color: #ffffff;
            position: relative;
            overflow: hidden;

            span {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background-color: #02ffff;
            }
        }

        .buttonGrp {
            text-align: center;
        }

        .oc_btn {
         
        }
    }
}


@media only screen and (max-width: 1280px) {
    .buySellbox {

        &::before {
            content: "";
            height: 100%;
            left: 12%;
            position: absolute;
            top: 0;
            background: -o-linear-gradient(348deg, #163954, #062a3b);
            background: linear-gradient(102deg, #163954, #062a3b);
            border: 1px solid #50dbff;
            border-radius: 14px;
            -webkit-transform: scale(1.05) perspective(300px) rotateX(350deg);
                    transform: scale(1.05) perspective(300px) rotateX(350deg);
            width: 80%;
            z-index: -1;
        }

        &::after {
            content: "";
            height: 100%;
            left: 22%;
            position: absolute;
            top: 10%;
            background: -o-linear-gradient(348deg, #163954, #062a3b);
            background: linear-gradient(102deg, #163954, #062a3b);
            border-radius: 14px;
            -webkit-transform: scale(1.05) perspective(300px) rotateX(350deg);
                    transform: scale(1.05) perspective(300px) rotateX(350deg);
            width: 60%;
            z-index: -1;
            border-bottom: 2px solid #02ffff;
        }

    }
}

@media only screen and (max-width: 767px) {
    .buySellbox {

        &::before {
            left: 2% !important;
            -webkit-transform: scale(1.05) perspective(300px) rotateX(350deg);
                    transform: scale(1.05) perspective(300px) rotateX(350deg);
            width: 95% !important;
        }

        &::after {
            height: 100%;
            left: 10% !important;
            top: 10%;
            -webkit-transform: scale(1.05) perspective(300px) rotateX(350deg);
                    transform: scale(1.05) perspective(300px) rotateX(350deg);
            width: 80% !important;
        }

    }
}