.statisics {
    margin: 0 0 60px;

    .boxinner {
        height: 100%;

        h2 {
            // padding: 0;
            display: block;
        }

        .box {
            position: relative;
            text-align: center;
            border-radius: 5px;




            h3 {
                color: #50dbff;
            }

            h5 {}

            p {
                margin: 0 0 15px;
            }

            strong {
                margin: 0 0 15px;
                display: block;
                color: #125d60;
            }
        }

        &.rightinfo {
            .box {
                border-color: #135e61;
                -webkit-box-shadow: none;
                box-shadow: none;
                position: relative;

                strong {
                    color: #ffffff;
                }
            }
        }
    }

    .copyReferralLink {
        cursor: pointer;
    }
}

@media only screen and (max-width: 767px) {
    .statisics .boxinner h2 {
        text-align: center;
        // color: #02ffef;
    }

    .statisics .boxinner.leftinfo {
        margin-bottom: 30px;
    }
}