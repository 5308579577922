.buyOc {
    margin: 0 0 60px;

    .buyOcbox {
        background-color: rgba(33, 78, 122, 0.31);
        padding: 30px;
        border-radius: 5px;
        height: 100%;
        border: 1px solid #50dbff;

        .topttl {
            display: block;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 8px;

            span {
                font-size: 14px;
            }
        }

        input {
            display: block;
            width: 100%;
            padding: 15px;
            background-color: rgba(255, 255, 255, 0.161);
            border: none;
            border-radius: 5px;
            margin-bottom: 8px;
            font-size: 20px;
            color: #ffffff;
        }

        .buttonGrp {
            
        }

        .oc_btn {
            min-width: auto;
        }
    }
}